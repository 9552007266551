<template>
  <div>
    <div class="router-view-container">
      <section class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </section>

      <main class="space-y-5">
        <!-- 選單項目 -->
        <draggable
          class="draggable1 grid lg:grid-cols-2 gap-9 mx-3 lg:mx-0"
          style="margin-top: 40px"
          group="list"
          v-model="lists"
          tag="ul"
          :component-data="getComponentData(null)"
          :empty-insert-threshold="emptyInsertThreshold"
          :fallbackOnBody="fallbackOnBody"
          :swapThreshold="swapThreshold"
          :animation="animation"
          :move="onMove"
          :dragClass="dragClass"
          :chosenClass="chosenClass"
          :ghostClass="ghostClass"
          handle=".handle2"
          @change="onChange"
          @update="onUpdate"
          @start="onStart"
          @end="onEnd"
          @choose="onChoose"
          @sort="onSort"
        >
          <li class="box p-4 relative hover:shadow-xl transition-shadow duration-300" v-for="(item1, index) in newLists" :key="item1.smno">
            <!-- absolute delete -->
            <div
              @click=";(deleteList_dialog = true), (editSmno = item1.smno)"
              class="
                absolute
                w-6
                h-6
                bg-red-400
                rounded-full
                top-0
                right-0
                transform
                translate-x-1/2
                -translate-y-1/2
                flex
                items-center
                justify-center
                cursor-pointer
                shadow-md
              "
            >
              <span class="w-4 h-1 bg-white rounded-full"></span>
            </div>
            <!-- absolute handle -->
            <div
              class="
                absolute
                w-6
                h-6
                bg-white
                border border-gray-divide
                rounded-full
                top-0
                left-0
                transform
                -translate-x-1/2 -translate-y-1/2
                flex
                items-center
                justify-center
                shadow-md
              "
            >
              <svg-icon class="handle2 xs" icon-class="drag" className="gray-divide"></svg-icon>
            </div>

            <section class="space-y-4">
              <div>
                <div class="flex items-center justify-between">
                  <h1 class="title">標題</h1>
                  <h1 class="text-xs text-gray-400">頁尾區塊 ( {{ index + 1 }} / 4 )</h1>
                </div>
                <div class="flex items-center justify-between">
                  <h1 class="text-lg font-medium">{{ item1.title }}</h1>
                  <vs-button @click="editList(item1)" transparent size="medium" color="success">編輯標題</vs-button>
                </div>
              </div>
              <hr />
              <div class="flex items-center justify-between">
                <h1 class="bigTitle">選單項目</h1>
                <vs-button transparent color="success"> </vs-button>
              </div>
              <draggable
                class="draggable2 space-y-2"
                group="list_item"
                :list="item1.arr"
                tag="ul"
                :component-data="getComponentData(index)"
                :empty-insert-threshold="emptyInsertThreshold"
                :fallbackOnBody="fallbackOnBody"
                :swapThreshold="swapThreshold"
                :animation="animation"
                :move="onMove"
                :dragClass="dragClass"
                :chosenClass="chosenClass"
                :ghostClass="ghostClass"
                handle=".handle"
                @change="onChange"
                @update="onUpdate"
                @start="onStart"
                @end="onEnd"
                @choose="onChoose"
                @sort="onSort"
              >
                <li class="item2_wrap" v-for="item2 in item1.arr" :key="item2.smno">
                  <div class="item2">
                    <div class="flex items-center w-0 flex-auto">
                      <svg-icon class="handle flex-shrink-0" icon-class="drag" className="gray-divide"></svg-icon>
                      <p class="text-limit-1">{{ item2.title }}</p>
                    </div>

                    <div class="flex flex-shrink-0">
                      <vs-button @click="editList(item2)" transparent size="small" color="success">編輯</vs-button>
                      <vs-button @click=";(deleteList_dialog = true), (editSmno = item2.smno)" transparent size="small" color="danger"
                        >刪除</vs-button
                      >
                    </div>
                  </div>
                </li>
              </draggable>
            </section>
            <!-- 新增選單按鈕 -->
            <div class="w-full border rounded-xl h-10 flex items-center px-2 cursor-pointer mt-2" @click="newList(item1.smno)">
              <div class="flex items-center space-x-2">
                <svg-icon icon-class="add" className="success"></svg-icon>
                <span class="font-medium text-success">新增選單</span>
              </div>
            </div>
          </li>
          <div
            @click="newList('', 1)"
            v-if="lists.length < 4"
            class="
              box
              border-dashed border-4 border-gray-200
              flex
              items-center
              justify-center
              hover:border-gray-divide
              transition-colors
              cursor-pointer
              group
            "
            style="background: transparent"
          >
            <div class="flex flex-col items-center justify-center space-y-1" style="min-height: 200px">
              <svg-icon icon-class="add" className="gray-divide xl"></svg-icon>
              <span class="font-medium text-gray-divide text-base">新增頁尾區塊</span>
            </div>
          </div>
        </draggable>
      </main>
    </div>

    <!-- drawer -->
    <ValidationObserver v-slot="{ invalid }">
      <drawer>
        <template #header>
          <h1 v-if="isEdit">編輯選單項目</h1>
          <h1 v-else>新增選單</h1>
        </template>

        <section class="space-y-4">
          <div>
            <h1 class="title">標題</h1>
            <div class="relative">
              <ValidationProvider mode="aggressive" name="標題" rules="required|max:50" v-slot="{ errors }">
                <vs-input placeholder="例如：關於我們" v-model="listTitle"></vs-input>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
          </div>

          <transition name="fade">
            <div v-if="typeLists.length > 0">
              <h1 class="title">類別</h1>
              <div class="relative">
                <ValidationProvider name="類別" rules="required" v-slot="{ errors }">
                  <vs-select @change="listTypeChanged()" v-model="listType">
                    <vs-option :label="item.title" :value="item.no" v-for="item in typeLists" :key="item.no">{{ item.title }}</vs-option>
                  </vs-select>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
          </transition>

          <transition name="fade">
            <div v-if="typeLists2.length > 0">
              <h1 class="title">項目</h1>

              <div class="relative">
                <ValidationProvider name="項目" rules="required" v-slot="{ errors }">
                  <vs-select v-model="listType2">
                    <vs-option :label="item.title" :value="item.no" v-for="item in typeLists2" :key="item.no">{{ item.title }}</vs-option>
                  </vs-select>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
          </transition>

          <transition name="fade">
            <div v-if="isDisabledSelect">
              <h1 class="title">項目</h1>
              <ValidationProvider mode="aggressive" name="項目" rules="required" v-slot="{ errors }">
                <vs-select disabled placeholder="此類別尚無任何項目"> </vs-select>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
          </transition>

          <transition name="fade">
            <div v-if="isUrlInput">
              <h1 class="title">網址</h1>

              <div class="relative">
                <ValidationProvider mode="aggressive" name="網址" rules="required" v-slot="{ errors }">
                  <vs-input placeholder="例如：https://www.google.com" v-model="urlInput"></vs-input>

                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
          </transition>

          <transition name="fade">
            <div v-if="isContent">
              <h1 class="title">自訂內文</h1>
              <div class="relative">
                <ValidationProvider mode="aggressive" name="自訂內文" rules="required" v-slot="{ errors }">
                  <myTextarea v-model="urlInput"></myTextarea>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
          </transition>
        </section>

        <template #footer>
          <div class="flex justify-end w-full space-x-4">
            <vs-button size="large" transparent class="font-medium" @click="close_drawer"> 取消 </vs-button>
            <vs-button size="large" class="font-medium" :disabled="invalid" @click="saveList(invalid, isEdit)" ref="saveBtn">
              <span v-if="isEdit">儲存</span>
              <span v-else>新增</span>
            </vs-button>
          </div>
        </template>
      </drawer>
    </ValidationObserver>

    <!-- 刪除選單 dialog -->
    <vs-dialog overflow-hidden blur v-model="deleteList_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除此選單？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的選單無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="deleteList_dialog = false">取消</vs-button>
          <vs-button @click="deleteList" size="large" transparent color="danger" ref="deleteListBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import drawer from '@/components/drawer.vue'
export default {
  name: 'menus-footer',
  components: {
    draggable,
    drawer,
  },
  data() {
    return {
      is_API_loaded: false,
      listTitle: '',
      typeLists: [], // 類別列表
      listType: '', // 選中的類型碼
      typeLists2: [], // 依照類別抓出的項目列表
      listType2: '', // 選中的類型碼2
      isUrlInput: false, // 類別選中網址時顯示
      urlInput: '', // url input輸入框 / 自訂內文輸入框
      isDisabledSelect: false, // 項目沒資料時顯示
      isEdit: false,
      editSmno: '', // 儲存/刪除選單時需要的smno碼
      newListSmno: '', // 新增選單到第二層要用到
      deleteList_dialog: false,
      isContent: false, // 自訂內文開
      deletedObj: {}, // 編輯標題時被刪除的自訂內文
      lists: [],
      animation: 150,
      forceFallback: false,
      swapThreshold: 1,
      fallbackOnBody: false,
      emptyInsertThreshold: 5,
      dragClass: 'dragClass', // 拖動時的懸浮樣式
      chosenClass: 'chosenClass', // 被選中的區塊樣式
      ghostClass: 'ghostClass',
      delay: 0,
    }
  },
  computed: {
    newLists() {
      let newAry = []

      this.lists.forEach((item) => {
        item.layers = 1
        if (item.arr.length > 0) {
          item.layers = 2

          item.arr.forEach((item2) => {
            if (item2.arr.length > 0) {
              item.layers = 3
            }
          })
        }
        newAry.push(item)
      })
      return newAry
    },
  },
  async created() {
    // 讀取初始資料
    const res = await Promise.all([this.getLists(), this.getListType()])
    // res會是陣列，若兩個都為true，關閉loading
    if (res.every((e) => e === true)) {
      this.is_API_loaded = true
    }
  },
  methods: {
    onEditorInput(val) {
      this.msg = val
    },
    onMove(evt, originalEvent) {
      // 放置的位置 draggable1 / draggable2 / draggable3
      let draggedPlace = evt.to.classList[0]
      // console.log('放置的位置', draggedPlace)

      let from_layers = evt.draggedContext.element.layers
      // console.log('from layer', from_layers)

      let to_layers = 0
      if (evt.to.__vue__.componentData.props.list !== undefined) {
        to_layers = evt.to.__vue__.componentData.props.list.layers
        // console.log('to layer', to_layers)
      }

      let total_layers = from_layers + to_layers
      // console.log('total_layers', total_layers)

      this.allowChange

      // 兩層選單可以放到第二層選單中
      if (from_layers <= 2 && draggedPlace === 'draggable2') {
        return true
        // 大於等於兩層的選單，不能放到第三層選單中
      } else if (from_layers >= 2 && draggedPlace === 'draggable3') {
        return false
        // 大於等於三層的選單，不能放到第二層
      } else if (from_layers >= 3 && draggedPlace === 'draggable2') {
        return false
      } else if (total_layers >= 5) {
        return false
      } else if (draggedPlace === 'draggable1') {
        return true
      }
    },
    getComponentData(index) {
      return {
        props: {
          list: this.newLists[index],
        },
      }
    },
    // 當元素被移動時觸發
    onChange(evt) {
      for (let item in evt) {
        if (item === 'added' || item === 'moved') {
          // 儲存選單
          this.saveArrange()
        }
      }
    },
    onChoose(evt) {
      // console.log('on onChoose', evt)
    },
    onUpdate(evt) {
      // console.log(evt)
    },
    onStart(evt) {
      // console.log(evt)
    },
    onSort(evt) {
      // console.log(evt)
    },
    onEnd(evt) {},
    // 2.2.2.1 讀取頁首清單列表
    getLists() {
      return this.$axios({
        url: 'front/menu/getMenuTopList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          type: 1,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.lists = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        return res.data.Success
      })
    },
    // 2.2.2.2頁首清單資料-新增,儲存列表
    saveList(invalid, isEdit) {
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      let url = ''
      let data = {}

      if (isEdit) {
        url = 'front/menu/uMenuTopData.php'
        data = {
          lang: 0,
          smno: this.editSmno,
          title: this.listTitle,
          no1: this.listType,
          no2: this.listType2,
          link: this.urlInput,
        }
      } else {
        url = 'front/menu/setMenuTopData.php'
        data = {
          lang: 0,
          type: 1,
          title: this.listTitle,
          no1: this.listType,
          no2: this.listType2,
          link: this.urlInput,
          smno: this.newListSmno, // 上一層的smno，若為空值代表新增區塊
        }
      }

      this.$axios({
        url: url,
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        console.log(res)
        if (res.data.Success) {
          this.getLists()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          loading.close()
          this.close_drawer()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 2.2.2.4 頁首清單資料-刪除
    deleteList() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteListBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/menu/dMenuTopData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smno: this.editSmno,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.getLists()
          loading.close()
          this.deleteList_dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 2.2.2.5 頁首清單資料-修改排序
    saveArrange() {
      this.$axios({
        url: 'front/menu/uRankMenuTopData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          // 轉成json 並且只保留smno跟arr
          smno: JSON.stringify(this.lists, ['smno', 'arr']),
        },
      }).then((res) => {
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.getLists()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.2.1.2 讀取類型第一層列表
    getListType() {
      return this.$axios({
        url: 'front/menu/getMenuTypeList1.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.typeLists = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        return res.data.Success
      })
    },
    // 2.2.1.3 讀取類型第二層列表
    listTypeChanged() {
      let val = this.listType
      this.typeLists2 = []
      this.urlInput = ''
      this.isUrlInput = false // 預設關閉網址輸入框
      this.isDisabledSelect = false // 預設關閉
      this.isContent = false
      if (!val) return

      // 若選擇到的是 自訂內文 / 網址 / 首頁，就不用讀取第二層
      if (val === 'H4lFwQoMTg2uEfJ6SYyAd5o/Sz6ycXC0XYxwaTNhOzk=') {
        // 首頁
        return
      } else if (val === 'iyKkW8PbItZbu46zpM6wTCmEp6bsSjJ/zr/SqrtDyOg=') {
        //  若選到自訂內文，顯示輸入框
        this.isContent = true
        return
      } else if (val === '/M25DHlZG8FNH9F*/sJVAcpqehNhSmeWP0eG*lvQd6I=') {
        // 若選到網址，顯示輸入框
        this.isUrlInput = true
        return
      }
      this.$axios({
        url: 'front/menu/getMenuTypeList2.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          no: this.listType,
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          if (res.data.Data.length === 0) this.isDisabledSelect = true
          this.typeLists2 = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 新增選單
    newList(smno, type = 0) {
      // type 0 子選單新增 / type 1 區塊新增

      let index = this.typeLists.findIndex((obj) => obj.title === '自訂內文')
      if (type === 1 && index > -1) {
        // 新增新區塊並且有找到自訂內文時，刪除自訂內文
        this.deletedObj = this.typeLists[index]
        this.typeLists.splice(index, 1)
      } else if (type === 0 && index === -1) {
        // 新增子選單並沒找到自訂內文，新增回去
        this.typeLists.unshift(this.deletedObj)
      }

      // 若找不到自訂內文選單，新增回去被刪除的obj
      // let index = this.typeLists.findIndex((obj) => obj.title === '自訂內文')
      // if (index === -1 && type === 0) {
      //   this.typeLists.unshift(this.deletedObj)
      // }
      this.newListSmno = smno
      this.isEdit = false
      this.listTitle = ''
      this.listType = ''
      this.listType2 = ''
      this.urlInput = ''
      this.open_drawer()
    },
    // 編輯選單
    editList(list) {
      let index = this.typeLists.findIndex((obj) => obj.title === '自訂內文')
      // 若是編輯標題，且自訂內文有在選單中，儲存index並儲存被刪除的obj，刪除obj
      if (list.level === 2) {
        if (index > -1) {
          this.deletedObj = this.typeLists[index]
          this.typeLists.splice(index, 1)
        }
      } else {
        // 若找不到自訂內文選單，新增回去被刪除的obj
        if (index === -1) {
          this.typeLists.unshift(this.deletedObj)
        }
      }
      this.isEdit = true
      this.listTitle = list.title
      this.listType = list.category
      this.listType2 = list.no
      this.editSmno = list.smno
      // 類別是不是網址或標題？
      setTimeout(() => {
        list.category === '/M25DHlZG8FNH9F*/sJVAcpqehNhSmeWP0eG*lvQd6I=' || 'iyKkW8PbItZbu46zpM6wTCmEp6bsSjJ/zr/SqrtDyOg='
          ? (this.urlInput = list.link)
          : (this.urlInput = '')
      }, 100)
      this.open_drawer()
    },
    // 開啟drawer選單
    open_drawer() {
      this.$store.commit('DRAWER_TOGGLER', {
        name: 'DRAWER_TOGGLER',
      })
      this.$store.commit('OVERLAY_TOGGLER', true)
    },
    // 關閉drawer選單
    close_drawer() {
      // this.$store.commit('DRAWER_TOGGLER', {
      //   name: '',
      // })
      this.$store.dispatch('closeOverlay')
    },
  },
  // 如果drawer是開的，離開路由時把它關上。
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.drawer) {
      // this.close_drawer()
      this.$store.dispatch('closeOverlay')
    }
    next()
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white w-full rounded-xl;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

// .draggable1 {
//   @apply space-y-2 pb-2;
// }

.item1,
.item2,
.item3 {
  @apply h-10 rounded-xl flex items-center justify-between border px-2;
}

.dragClass {
  @apply shadow-lg border-success bg-white opacity-100 rounded-xl;
}

.chosenClass {
  @apply border border-success rounded-xl;
}

// .ghostClass {
//   @apply bg-gray-background rounded-xl;
// }

.handle {
  cursor: grab;
  margin: 0px 8px 0px 0px;
}
.handle2 {
  cursor: grab;
}
</style>
